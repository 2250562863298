import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout_pictures"
import SEO from "../components/seo"



const caption="text-small mb-6 w-64 p-3 text-lg text-center"
const wrapper= "place-content-center flex flex-wrap"
const image_container = " m-4 w-72"
const image = "w-72 h-96 align-center flex rounded-t"
const caption_wide="text-small mb-6 w-96 p-3 text-lg text-center"
const image_container_wide = "mx-4 w-96 my-10 object-contain"
const image_wide = "w-96 h-72 align-center flex rounded-t flex-wrap"

const PicturesPage = () => (
  <Layout>
    <SEO title="Gallery" />
    <h1 className="m-12">Photo Gallery</h1>
    <div class="overflow-hidden text-gray-700 mx-10 xl:mx-14">
      <div class={wrapper}>
        <div class={image_container}>
          <StaticImage
            src="../images/laker.jpg"
            alt="a girl holding a fish"
            class={image}
          />
          <p class={caption}>An Impressive Laker</p>
        </div>
        <div class={image_container}>
          <StaticImage
            src="../images/bass_boy.jpg"
            alt="Ron Ditch and Son"
            class={image}
          />
          <p class={caption}>A new enthusiast with his first over 6# smallmouth bass</p>
        </div>

        <div class={image_container}>
          <StaticImage
            src="../images/boyandfish.jpg"
            alt="Ron Ditch and Son"
            class={image}
          />
          <p class={caption}>Proud 4th Generation Guide</p>
        </div>
        <div class={image_container}>
          <StaticImage
            src="../images/walleye.jpg"
            alt="Ron Ditch and Son"
            class={image}
          />
          <p class={caption}>A nice catch of walleye</p>
        </div>
        <div class={image_container_wide}>
            <StaticImage
              src="../images/Ron_Ditch (1).jpg"
              alt="Ron Ditch and Son"
              class={image_wide}
            />
            <p class={caption_wide}> nice catch of bass ranging from 3 to 5 pounds !!</p>
          </div>
          <div class={image_container_wide}>
          <StaticImage
            src="../images/fish_index.jpg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}>A LIMIT OF "GOBY" FED LUNKERS 8/2018</p>
        </div>
        <div class={image_container_wide}>
            <StaticImage
              src="../images/dery_winner.jpg"
              alt="Ron Ditch and Son"
              class={image_wide}
            />
            <p class={caption_wide}>
ANOTHER LIMIT !!--OPENING WEEK -- 2013 <br />
DERBY WINNER!
</p>
          </div>
        <div class={image_container}>
          <StaticImage
            src="../images/king_salmon.jpg"
            alt="Ron Ditch and Son"
            class={image}
          />
          <p class={caption}>A king salmon</p>
        </div>
        <div class={image_container}>
          <StaticImage
            src="../images/index_lighthouse.jpg"
            alt="Ron Ditch and Son"
            class={image}
          />
          <p class={caption}>GALOO ISLAND LIGHTHOUSE</p>
        </div>
        <div class={image_container}>
          <StaticImage
            src="../images/aaron_ditch.jpg"
            alt="Ron Ditch and Son"
            class={image}
          />
          <p class={caption}>AARON DITCH WITH 5.5# BASS </p>
        </div>
        <div class={image_container}>
          <StaticImage
            src="../images/derby_winner.jpg"
            alt="Ron Ditch and Son"
            class={image}
          />
          <p class={caption}>DERBY WINNER!</p>
        </div>

          <div class={image_container_wide}>
          <StaticImage
            src="../images/tearny.jpg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}>TEARNEY PARTY</p>
        </div>
        <div class={image_container_wide}>
            <StaticImage
              src="../images/nancy_boys.jpg"
              alt="Ron Ditch and Son"
              class={image_wide}
            />
            <p class={caption_wide}> NANCY, JIM, RON AND THE BOYS WITH A NICE CATCH OF BASS</p>
          </div>

          <div class={image_container_wide}>
          <StaticImage
            src="../images/ruddys_family_three.jpg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}>You're never too old or too pretty to admire these hogs taken by Ron and this
smiling mother & daughter and the camera-shy  grandkids. All the fish are in the 3-5+
lb range!</p>
        </div>
        <div class={image_container_wide}>
          <StaticImage
            src="../images/keena.jpg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}>You're never too young to start fishing! This young man admires
his catch taken from the dock at Ron's fish camp </p>
        </div>
        <div class={image_container_wide}>
          <StaticImage
            src="../images/ruddy_grandpa.jpg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}>A NICE CATCH OF KING SALMON </p>
        </div>
        <div class={image_container_wide}>
          <StaticImage
            src="../images/dave_mavis.jpg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}>DAVE & MAVIS SHOW OFF A NICE CATCH OF BROWNS</p>
        </div>
        <div class={image_container_wide}>
          <StaticImage
            src="../images/5_bass.jpg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}>A NICE 5# BASS</p>
        </div>
        <div class={image_container_wide}>
          <StaticImage
            src="../images/rich_2.jpeg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}>Rich had never caught a bass over 15"--UNTIL TODAY!  6 FISH OVER 16"!
</p>
        </div>
        <div class={image_container_wide}>
          <StaticImage
            src="../images/rich_1.jpeg"
            alt="Ron Ditch and Son"
            class={image_wide}
          />
          <p class={caption_wide}></p>
        </div>
        </div>

    </div>

  </Layout>
)

export default PicturesPage
