import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
import background from "../images/ripples.png"

import "./layout.css"

const Layout = ({ children }) => {

  return (
    <div>
       <Header siteTitle="Ron Ditch & Son" siteDescription="The most experienced Charter Service on Lake Ontario" />
      <div
        style={{
          padding: `0 1.0875rem 1.45rem`,
          maxWidth: 1500,

        }}
        className="my-14 mx-auto"
      >
        <main className="min-h-screen">{children}</main>
      </div>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
